import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import Nav from '../components/Navigation';
import Availability from '../components/Availability';

type Theme = 'cover' | 'image' | 'dark' | 'light';

interface Header {
  theme : Theme,
  currentpage : any,
  title? : string,
  subtitle? : string,
  avatar? : string
}

export default function Header({ theme, currentpage, title, subtitle, avatar } : Header) {
  const navTheme : Theme = theme === 'light' ? 'dark' : 'light';
  const [menu, setMenu] = useState('open'); 
  const { t, i18n } = useTranslation();

  return ( 
    <>
      <Nav 
        currentpage={currentpage}
        theme={navTheme}
      />
      <header className={`header--${theme}`}>
        {theme === 'cover'  && (
          <>
            <div className="grid">
              <Availability 
                currentpage={currentpage} 
                variant='message' 
              />
            </div>
            <div className="grid">
              <h1>{title}</h1>
              {subtitle && <p>{subtitle}</p>}
            </div>
          </>
        )}

        {theme === 'image'  && (
          <>
            <div className="grid">
              <Availability 
                currentpage={currentpage} 
                variant='message' 
              />
            </div>
            <div className="grid">
              <div className="grid__column grid__column--five">
                <h1>{title}</h1>
                {subtitle && <p>{subtitle}</p>}
              </div>
            </div>
          </>
        )}


        {theme === 'dark'  && (
          <>
            <div className="grid">
              <Availability 
                currentpage={currentpage} 
                variant='message' 
              />
            </div>
            <div className="grid">
              <h1>{title}</h1>
              {subtitle && <p>{subtitle}</p>}
            </div>
          </>
        )}

        {theme === 'light' &&
          <div className="header__top"></div>
        }
        
        {theme === 'cover'  && 
          <div className="mouse-container">
            <div className="mouse"></div>
          </div> 
        }
        {avatar && (
          <div className="header__avatar">
            <img src={avatar} alt={t('general-name')} />
          </div>
        )}
      </header>
    </>
  )
}
